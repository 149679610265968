import { useState, useEffect } from "react";

const Stopwatch = ({
  clickedSymbol,
  time,
  setTime,
  serverTime,
  setServerTime,
}) => {
  //const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRunning(true);
    }, 1000);

    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 100);
        setServerTime((prevTime) => prevTime + 100);
      }, 100);
    } else if (!running) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [running]);

  /*   useEffect(() => {
    if (clickedSymbol.nomatch > 0) {
      setTime((prevTime) => prevTime + 10000);
    }
  }, [clickedSymbol.nomatch]); */

  return (
    <>
      <span>{("0" + Math.floor((serverTime / 60000) % 60)).slice(-2)}:</span>
      <span>{("0" + Math.floor((serverTime / 1000) % 60)).slice(-2)}</span>
      {/* <span>{("0" + ((serverTime / 10) % 100)).slice(-2)}</span> */}
    </>
  );
};

export default Stopwatch;
