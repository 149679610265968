import { useState, useEffect, memo, useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { staggerBunsFade, bunFade } from "utils/animations";
import Bun from "./Bun";

const bunsArrayDyn = [
  [1, 2, 3, 4, 5, 6],
  [1, 7, 8, 9, 10, 11],
  [1, 12, 13, 14, 15, 16],
  [1, 17, 18, 19, 20, 21],
  [1, 22, 23, 24, 25, 26],
  [1, 27, 28, 29, 30, 31],
  [2, 7, 12, 17, 22, 27],
  [2, 8, 13, 18, 23, 28],
  [2, 9, 14, 19, 24, 29],
  [2, 10, 15, 20, 25, 30],
  [2, 11, 16, 21, 26, 31],
  [3, 7, 13, 19, 25, 31],
  [3, 8, 14, 20, 26, 27],
  [3, 9, 15, 21, 22, 28],
  [3, 10, 16, 17, 23, 29],
  [3, 11, 12, 18, 24, 30],
  [4, 7, 14, 21, 23, 30],
  [4, 8, 15, 17, 24, 31],
  [4, 9, 16, 18, 25, 27],
  [4, 10, 12, 19, 26, 28],
  [4, 11, 13, 20, 22, 29],
  [5, 7, 15, 18, 26, 29],
  [5, 8, 16, 19, 22, 30],
  [5, 9, 12, 20, 23, 31],
  [5, 10, 13, 21, 24, 27],
  [5, 11, 14, 17, 25, 28],
  [6, 7, 16, 20, 24, 28],
  [6, 8, 12, 21, 25, 29],
  [6, 9, 13, 17, 26, 30],
  [6, 10, 14, 18, 22, 31],
  [6, 11, 15, 19, 23, 27],
];
const Buns = memo(
  ({
    clickedSymbol,
    setClickedSymbol,
    time,
    setTime,
    serverTime,
    setServerTime,
  }) => {
    //const [bunsArrayDyn, setBunsArrayDyn] = useState(bunsCollection);

    return (
      <div className="row">
        <div className="col-md-6 d-flex justify-content-center justify-content-md-end">
          <Bun
            key="1"
            position="one"
            clickedSymbol={clickedSymbol}
            setClickedSymbol={setClickedSymbol}
            bunsArrayDyn={bunsArrayDyn}
            time={time}
            setTime={setTime}
            serverTime={serverTime}
            setServerTime={setServerTime}
          />
        </div>
        <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
          <Bun
            key="2"
            position="two"
            clickedSymbol={clickedSymbol}
            setClickedSymbol={setClickedSymbol}
            bunsArrayDyn={bunsArrayDyn}
            time={time}
            setTime={setTime}
            serverTime={serverTime}
            setServerTime={setServerTime}
          />
        </div>
      </div>
    );
  }
);

export default Buns;
