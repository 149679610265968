import useSound from "use-sound";
import { useDispatch, useSelector } from "react-redux";
import { isSoundEnabledReducer } from "../../store/gameSlice";
import soundNavigate from "../../assets/sounds/navigate.wav";

function Button({ onClick, className, label }) {
  const isSoundEnabled = useSelector((state) => state.game.isSoundEnabled);

  const [playNavigate] = useSound(soundNavigate, {
    volume: 1,
    soundEnabled: isSoundEnabled,
  });

  const clickHandler = () => {
    playNavigate();
    if (onClick) {
      onClick();
    }
  };

  return (
    <button type="submit" className={className} onClick={clickHandler}>
      {label}
    </button>
  );
}

export default Button;
