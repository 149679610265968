import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { staggerItemsFade, itemFade } from "utils/animations";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import Logo from "components/Logo/Logo";

import imgInst1 from "assets/images/inst1.svg";
import imgInst2 from "assets/images/inst2.svg";
import imgInst3 from "assets/images/inst3.svg";
import imgNagrada1 from "assets/images/nagrada1.png";
import imgNagrada2 from "assets/images/nagrada2.png";
import imgNagrada3 from "assets/images/nagrada3.png";
import imgNagrada4 from "assets/images/nagrada4.png";

import imgClose from "assets/images/close.svg";
import { click } from "@testing-library/user-event/dist/click";

function Nagrade() {
  let navigate = useNavigate();

  return (
    <>
      <Logo />
      <Wrapper className="nagrade mx-auto py-4 px-1 py-sm-5">
        <motion.div
          variants={staggerItemsFade}
          initial="hidden"
          animate="show"
          className="position-relative w-100 bg-white text-center rounded-2 text-pink p-4 ">
          <img
            className="close d-none"
            onClick={() => navigate(-1)}
            src={imgClose}
          />
          <motion.div variants={itemFade}>
            <div className="h-stars">
              <h1 className="display-5 fw-bold mt-3 mb-0 text-golden pb-2 ">
                Nagrade
              </h1>
            </div>
          </motion.div>

          <div className="row g-2 my-2 my-sm-3">
            <motion.div
              variants={itemFade}
              className="usp col-12 col-sm-3 text-center mb-4">
              <img src={imgNagrada1} />
              <div className="mt-2 px-sm-2">
                <h4 className="text-golden">1. nagrada</h4>
                <strong>
                  Darilna kartica 250 €<br />+ dva Favorina izdelka
                </strong>
              </div>
            </motion.div>
            <motion.div
              variants={itemFade}
              className="usp col-12 col-sm-3 text-center mb-4">
              <img src={imgNagrada2} />{" "}
              <div className=" mt-2 px-sm-2">
                <h4 className="text-golden">2. nagrada</h4>
                <strong>
                  Darilna kartica 150 €<br />+ dva Favorina izdelka
                </strong>
              </div>
            </motion.div>
            <motion.div
              variants={itemFade}
              className="usp col-12 col-sm-3 text-center mb-4">
              <img src={imgNagrada3} />{" "}
              <div className=" mt-2 px-sm-2">
                <h4 className="text-golden">3. nagrada</h4>
                <strong>
                  Darilna kartica 100 €<br />+ dva Favorina izdelka
                </strong>
              </div>
            </motion.div>
            <motion.div
              variants={itemFade}
              className="usp col-12 col-sm-3 text-center mb-4">
              <img src={imgNagrada4} />{" "}
              <div className=" mt-2 px-sm-2">
                <h4 className="text-golden">4.-10. nagrada</h4>
                <strong>
                  Darilna kartica 40 €<br />+ dva Favorina izdelka
                </strong>
              </div>
            </motion.div>
          </div>

          <motion.div variants={itemFade}>
            <Button
              onClick={() => navigate(-1)}
              label="Zapri"
              className="btn btn-gold text-white px-5 mt-4"
            />
          </motion.div>
        </motion.div>
      </Wrapper>
    </>
  );
}

export default Nagrade;
