import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { isOptedInReducer } from "store/gameSlice";
import useSound from "use-sound";
import axios from "axios";

import { staggerItemsFade, itemFade } from "utils/animations";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import Logo from "components/Logo/Logo";

import soundLeaderboard from "assets/sounds/leaderboard.mp3";
import soundLeaderboardTop from "assets/sounds/win.wav";

function Optin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { location } = useParams();

  const isSoundEnabled = useSelector((state) => state.game.isSoundEnabled);

  const [playLeaderboard] = useSound(soundLeaderboard, {
    volume: 1,
    soundEnabled: isSoundEnabled,
  });

  const [playLeaderboardTop] = useSound(soundLeaderboardTop, {
    volume: 1,
    soundEnabled: isSoundEnabled,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let dataUserAPI = {
      quizId: "51a06c68-65b2-ldlfav",
      name: data.name,
      email: data.email,
      age_group: "11-17",
      newsletter: data.newsletter,
      terms: data.optin,
    };

    if (location === "start") {
      addUser(dataUserAPI);
    }

    if (location === "konec") {
      updateUser(dataUserAPI);
    }
  };

  const skipOptin = () => {
    if (location === "konec") {
      navigate("/lestvica");
    } else {
      addGuest();
    }
  };

  let dataGuestAPI = {
    quizId: "51a06c68-65b2-ldlfav",
    guest: true,
  };

  const addGuest = async () => {
    try {
      const response = await axios.post(
        "https://api.escapebox.si/quizbox/user",
        dataGuestAPI
      );
      localStorage.setItem("token", JSON.stringify(response.data.token));
      navigate("/pravila");
      //console.log("data", response.data);
    } catch (error) {
      console.log(error);
    } finally {
      //console.log("finally");
    }
  };

  const addUser = async (dataUserAPI) => {
    try {
      const response = await axios.post(
        "https://api.escapebox.si/quizbox/user",
        dataUserAPI
      );
      localStorage.setItem("token", JSON.stringify(response.data.token));
      dispatch(isOptedInReducer(true));

      navigate("/pravila");

      //console.log("data", response.data);
    } catch (error) {
      console.log(error);
    } finally {
      //console.log("finally");
    }
  };

  const updateUser = async (dataUserAPI) => {
    try {
      const response = await axios.put(
        "https://api.escapebox.si/quizbox/user",
        dataUserAPI,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      dispatch(isOptedInReducer(true));
      navigate("/lestvica");

      //console.log("data", response.data);
    } catch (error) {
      console.log(error);
    } finally {
      //console.log("finally");
    }
  };

  return (
    <>
      <Logo />
      <Wrapper className="optin px-3 py-4 py-sm-5 mx-auto">
        <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
          <motion.div variants={itemFade}>
            <h1 className="text-golden fw-bold display-5 pb-2 text-center">
              Poteguj se za
              <br />
              najslajše nagrade
            </h1>

            <p className="text-center">
              Vpiši svoje podatke in sodeluj v nagradnem žrebu.
            </p>
          </motion.div>
          <motion.div variants={itemFade}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control"
                  id="name"
                  placeholder="Vpiši svoje ime"
                  {...register("name", {
                    required: "Vpiši svoje ime.",
                  })}
                />
                <label htmlFor="name">Tvoje ime</label>
                {errors.name && (
                  <p className="errorMsg">{errors.name.message}</p>
                )}
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Vpiši svoj elektronski naslov"
                  {...register("email", {
                    required: "Vpiši svoj elektronski naslov.",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: "Elektronski naslov ni pravilen.",
                    },
                  })}
                />
                <label htmlFor="email">Elektronski naslov</label>
                {errors.email && (
                  <p className="errorMsg">{errors.email.message}</p>
                )}
              </div>
              <div className="d-none">
                <p className="lead mb-1">Koliko let imaš?</p>
                <div className="form-check form-check-inline mb-4">
                  <input
                    {...register("age")}
                    className="form-check-input"
                    type="radio"
                    name="age"
                    id="ten"
                    value="10"
                  />
                  <label className="form-check-label" htmlFor="ten">
                    Do 10 let
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    {...register("age")}
                    className="form-check-input"
                    type="radio"
                    name="age"
                    id="eleven"
                    value="11-17"
                  />
                  <label className="form-check-label" htmlFor="eleven">
                    11-17 let
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    {...register("age")}
                    className="form-check-input"
                    type="radio"
                    name="age"
                    id="eighteen"
                    value="18+"
                  />
                  <label className="form-check-label" htmlFor="eighteen">
                    18+ let
                  </label>
                </div>
                {errors.age && <p className="errorMsg">{errors.age.message}</p>}
              </div>
              <div>
                <div className="form-check">
                  <input
                    {...register("optin", {
                      required: "Strinjati se moraš s pravili in pogoji.",
                    })}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="optin"
                  />
                  <label className="form-check-label" htmlFor="optin">
                    Strinjam se s{" "}
                    <a
                      className="text-yellow"
                      href="https://lidl-favorina-2022.escapebox.si/files/pravila?v1"
                      target="_blank">
                      {" "}
                      pravili in pogoji{" "}
                    </a>{" "}
                    nagradne igre Najslajši del praznikov.
                  </label>
                  {errors.optin && (
                    <p className="errorMsg">{errors.optin.message}</p>
                  )}
                </div>

                <div className="form-check">
                  <input
                    {...register("newsletter")}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="newsletter"
                  />
                  <label className="form-check-label" htmlFor="newsletter">
                    Želim se prijaviti na Lidlove spletne novice.
                  </label>
                </div>
              </div>
              <div className="buttons text-center mt-4 mx-auto">
                {location === "konec" ? (
                  <Button label="Sodeluj" className="btn btn-gold w-100" />
                ) : (
                  <Button label="Igraj" className="btn btn-gold w-100" />
                )}
              </div>
            </form>
            <div className="buttons text-center mx-auto">
              {location === "konec" ? (
                <>
                  <Button
                    onClick={skipOptin}
                    label="Oglej si lestvico"
                    className="btn btn-lg btn-outline-white mt-1 w-100"
                  />
                  <p className="small">
                    (Toda brez možnosti za osvojitev nagrad.)
                  </p>
                </>
              ) : (
                <>
                  <Button
                    onClick={skipOptin}
                    label="Igraj kot gost"
                    className="btn btn-lg btn-outline-white mt-1 w-100"
                  />
                  <p className="small">
                    (Toda brez možnosti za osvojitev nagrad.)
                  </p>
                </>
              )}
            </div>
          </motion.div>
        </motion.div>
      </Wrapper>
    </>
  );
}

export default Optin;
