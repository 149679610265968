import { motion, AnimatePresence } from "framer-motion";
import { fadeInOut } from "utils/animations";

const Wrapper = ({ children, className }) => {
  return (
    <motion.div
      key={className}
      className={`wrapper  ${className}`}
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit">
      {children}
    </motion.div>
  );
};

export default Wrapper;
