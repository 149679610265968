import { useState, useMemo } from "react";
import { motion } from "framer-motion";
import { useStopwatch } from "react-timer-hook";
import Stopwatch from "./Stopwatch";

import imgTime from "assets/images/icon_time.svg";
import imgMatch from "assets/images/icon_match.svg";
import imgClicked from "assets/images/icon_clicked.svg";

function Status({ clickedSymbol, time, setTime, serverTime, setServerTime }) {
  return (
    <div className="status d-flex justify-content-between">
      <div className="time d-flex align-items-center rounded-5">
        <img className="pe-2" src={imgTime} />
        <span>
          <Stopwatch
            time={time}
            setTime={setTime}
            serverTime={serverTime}
            setServerTime={setServerTime}
            clickedSymbol={clickedSymbol}
          />
        </span>
      </div>

      <div className="clicked d-flex align-items-center justify-content-end rounded-5">
        <img className="pe-2" src={imgClicked} />
        <span>{clickedSymbol.clicked}/25</span>
        <img className="ps-2 pe-2" src={imgMatch} />
        <span className="text-green">{clickedSymbol.match}</span>
      </div>
    </div>
  );
}

export default Status;
