import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSoundEnabled: true,
  isOptedIn: false,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    isSoundEnabledReducer: (state, action) => {
      state.isSoundEnabled = action.payload;
    },
    isOptedInReducer: (state, action) => {
      state.isOptedIn = action.payload;
    },
  },
});

export const { isSoundEnabledReducer, isOptedInReducer } = gameSlice.actions;

export default gameSlice.reducer;
