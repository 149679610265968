import { useEffect } from "react";
import useSound from "use-sound";
import soundLeaderboard from "assets/sounds/leaderboard.mp3";
import soundLeaderboardTop from "assets/sounds/win.wav";

export function SoundLeaderboard() {
  const [play] = useSound(soundLeaderboard, { interrupt: true });

  useEffect(() => {
    play();
  }, [play]);
  return null;
}

export function SoundLeaderboardTop() {
  const [play] = useSound(soundLeaderboardTop, { interrupt: true });

  useEffect(() => {
    play();
  }, [play]);
  return null;
}
