import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { staggerItemsFade, itemFade } from "utils/animations";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import Logo from "components/Logo/Logo";

import imgInst1 from "assets/images/inst1.svg";
import imgInst2 from "assets/images/inst2.svg";
import imgInst3 from "assets/images/inst3.svg";
import imgInst4 from "assets/images/inst4.png";
import imgClose from "assets/images/close.svg";
import { click } from "@testing-library/user-event/dist/click";

function Instructions({ play, pause, sound, play2, pause2, sound2 }) {
  let navigate = useNavigate();

  const clickhandler = () => {
    //pause();
    /* play(); */

    sound.fade(0.1, 0, 4000);

    setTimeout(() => {
      play2();
      sound2.fade(0, 0.3, 1000);
    }, 3000);

    navigate("/igra");
  };

  useEffect(() => {
    pause2();
  }, []);

  return (
    <>
      <Logo />
      <Wrapper className="instructions mx-auto py-4 px-2 py-sm-5">
        <motion.div
          variants={staggerItemsFade}
          initial="hidden"
          animate="show"
          className="position-relative w-100 bg-white text-center rounded-2 text-pink p-4 ">
          <img
            className="close d-none"
            onClick={() => navigate(-1)}
            src={imgClose}
          />
          <motion.div variants={itemFade}>
            <h3 className="text-pink mb-3">Kako igrati?</h3>
            <p className="px-sm-6 d-none">
              Igra vsebuje 30 Lidlovih prazničnih kartic. Na vsaki je 6
              simbolov. Vedno se ujema samo po EN simbol na vsaki kartici.
            </p>
          </motion.div>

          <div className="row g-0 my-2 my-sm-5">
            <motion.div
              variants={itemFade}
              className="usp col-6 col-sm-3 text-center mt-2 mt-sm-0">
              <img src={imgInst1} />{" "}
              <p className="fw-semibold mt-1 px-sm-3">
                Pred tabo se bosta pojavili dve karti.
              </p>
            </motion.div>
            <motion.div
              variants={itemFade}
              className="usp col-6 col-sm-3 text-center mt-2 mt-sm-0">
              <img src={imgInst2} />{" "}
              <p className="fw-semibold mt-1 px-sm-3">
                Na vsaki poišči skupen simbol.
              </p>
            </motion.div>
            <motion.div
              variants={itemFade}
              className="usp col-6 col-sm-3 text-center mt-2 mt-sm-0">
              <img src={imgInst3} />{" "}
              <p className="fw-semibold mt-1 px-sm-3">
                S klikom na pravega odkriješ par.
              </p>
            </motion.div>
            <motion.div
              variants={itemFade}
              className="usp col-6 col-sm-3 text-center mt-2 mt-sm-0">
              <img src={imgInst4} />{" "}
              <p className="fw-semibold mt-1 px-sm-3">
                Vsaka napaka pomeni pribitek 10 sekund.
              </p>
            </motion.div>
          </div>
          <motion.p variants={itemFade} className="lead fw-bold text-center ">
            Čim hitreje poišči vseh 25 parov in se
            <br />
            uvrsti na lestvico najboljših!
          </motion.p>
          <motion.div variants={itemFade}>
            <Button
              onClick={clickhandler}
              label="Začni igro"
              className="btn btn-gold text-white px-5 mt-4"
            />
          </motion.div>
        </motion.div>
      </Wrapper>
    </>
  );
}

export default Instructions;
