import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isOptedInReducer } from "store/gameSlice";
import { motion } from "framer-motion";

// Redux
import { isSoundEnabledReducer } from "store/gameSlice";

import { staggerItemsFade, itemFade } from "utils/animations";
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgBun from "assets/images/home_bun_new.png";
import imgUsp1 from "assets/images/usp01.svg";
import imgUsp2 from "assets/images/usp02.svg";
import imgUsp3 from "assets/images/usp03.svg";

function Frontpage({ play, sound, pause }) {
  let navigate = useNavigate();
  const isSoundEnabled = useSelector((state) => state.game.isSoundEnabled);
  const dispatch = useDispatch();

  const clickHandler = () => {
    navigate("/prijava/start");
    pause();

    if (isSoundEnabled) {
      sound.play();
      sound.fade(0, 0.2, 2000);
    }
  };

  useEffect(() => {
    localStorage.removeItem("token");
    dispatch(isOptedInReducer(false));
  }, []);

  return (
    <Wrapper className="frontpage px-3 px-md-7">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <motion.div
          variants={itemFade}
          className="row g-0 d-flex align-items-center">
          <div className="bun col-12 text-center">
            <img src={imgBun} />
          </div>

          <div className="col-12 text-center ">
            <p className="lead mt-1 mb-4 mb-0 px-sm-7">
              Preizkusi se v Lidlovi zabavni
              <br />
              decembrski igri in odnesi sladke nagrade.
            </p>
          </div>
        </motion.div>

        <div className="row g-sm-5 px-md-6">
          <motion.div variants={itemFade} className="col-4 text-center">
            <img src={imgUsp1} />{" "}
            <p className="fw-semibold mt-1 px-sm-3">
              S klikom na pravi simbol odkrij par.
            </p>
          </motion.div>
          <motion.div variants={itemFade} className="col-4 text-center">
            <img src={imgUsp2} />{" "}
            <p className="fw-semibold mt-1 px-sm-3">
              Uvrsti se na lestvico najboljših.
            </p>
          </motion.div>
          <motion.div variants={itemFade} className="col-4 text-center">
            <img src={imgUsp3} />{" "}
            <p className="fw-semibold mt-1 px-sm-3">
              Poteguj se za lepe nagrade.
            </p>
          </motion.div>
        </div>

        <motion.div
          variants={itemFade}
          className="text-center mt-5 button-container">
          <div className="row g-2">
            <div className="col-12 ">
              <Button
                onClick={clickHandler}
                label="Igraj"
                className="btn btn-gold px-6 w-100"
              />
            </div>

            <div className="col-sm-4">
              <Button
                onClick={() => navigate("/nagrade")}
                label="Nagrade"
                className="btn btn-sm btn-outline-white w-100"
              />
            </div>
            <div className="col-sm-8">
              <a
                className="btn btn-sm btn-outline-white w-100"
                href="https://lidl-favorina-2022.escapebox.si/files/pravilainpogoji.pdf?v1"
                target="_blank"
                rel="noreferrer">
                Pravila in pogoji
              </a>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </Wrapper>
  );
}

export default Frontpage;
