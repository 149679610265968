import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import useSound from "use-sound";
import { useSelector } from "react-redux";
import { staggerBunsFade, bunFade } from "utils/animations";
import axios from "axios";

import soundMatch from "assets/sounds/match.mp3";
import soundNomatch from "assets/sounds/nomatch.wav";
import soundLeaderboard from "assets/sounds/leaderboard.mp3";

const Bun = ({
  position,
  clickedSymbol,
  setClickedSymbol,
  bunsArrayDyn,
  time,
  setTime,
  serverTime,
  setServerTime,
}) => {
  const [showBun, setShowBun] = useState(true);
  const [showBoost, setShowBoost] = useState(false);
  const [matchImg, setMatchImg] = useState();
  const [randomBun, setRandomBun] = useState([]);
  //const [timeStep, setTimeStep] = useState(0);

  const isSoundEnabled = useSelector((state) => state.game.isSoundEnabled);

  const [playMatch] = useSound(soundMatch, {
    volume: 1,
    soundEnabled: isSoundEnabled,
  });

  const [playNomatch] = useSound(soundNomatch, {
    volume: 1,
    soundEnabled: isSoundEnabled,
  });

  const [playLeaderboard] = useSound(soundLeaderboard, {
    volume: 1,
    soundEnabled: isSoundEnabled,
  });

  const clickHandlerMatch = (event) => {
    const numMatch = document.querySelectorAll("div#" + event.target.id).length;

    //setTimeStep(time / 1000);

    let dataAPI = {
      stepId: clickedSymbol.clicked + 1,
      correct: numMatch === 2,
      time: time,
      totalTime: time,
    };

    setTime(0);

    setClickedSymbol({
      ...clickedSymbol,
      clicked: clickedSymbol.clicked + 1,
      match: numMatch === 2 ? clickedSymbol.match + 1 : clickedSymbol.match,
      nomatch:
        numMatch === 1 ? clickedSymbol.nomatch + 1 : clickedSymbol.nomatch,
    });

    const sendTime = async () => {
      try {
        const response = await axios.post(
          "https://api.escapebox.si/quizbox/speed/51a06c68-65b2-ldlfav",
          dataAPI,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        const alignTime = response.data.totalTime;
        setServerTime(alignTime);
      } catch (error) {
        console.log(error);
      } finally {
        //console.log("finally");
      }
    };

    sendTime();

    if (numMatch === 2) {
      setShowBoost("win");
      playMatch();
      setTimeout(() => {
        setShowBoost("hidden");
      }, 1000);
    } else if (numMatch === 1) {
      setShowBoost("lose");
      playNomatch();
      setTimeout(() => {
        setShowBoost("hidden");
      }, 1000);
    }

    setMatchImg(event.target.src.split("/").pop());
  };

  const generateBun = randomBun.map((item, index) => {
    return (
      <motion.div
        variants={bunFade}
        key={`${position}_${index}`}
        id={`item_${item}`}
        className={`bun-item bun-item__${index + 1}`}>
        <img
          style={{
            padding: "5px",
          }}
          onClick={clickHandlerMatch}
          id={`item_${item}`}
          src={process.env.PUBLIC_URL + "/imgs/" + item + ".png?v4"}
        />
      </motion.div>
    );
  });

  useEffect(() => {
    setShowBun(false);
    setTimeout(() => {
      var chooser = randomNoRepeats(bunsArrayDyn);
      setRandomBun(chooser);
      setShowBun(true);
    }, 500);
  }, [clickedSymbol]);

  return (
    <>
      <motion.div
        id="spotit-bun"
        variants={staggerBunsFade}
        initial="hidden"
        animate={showBun ? "show" : "hidden"}
        className={`spotit-bun ${
          position === "one"
            ? "spotit-bun__decorleft"
            : "spotit-bun__decorright"
        }`}>
        {showBoost === "win" && (
          <div className="score-booster win">
            <img src={process.env.PUBLIC_URL + "imgs/" + matchImg + "?v4"} />
          </div>
        )}

        {showBoost === "lose" && (
          <div className="score-booster lose">
            <span>+10s</span>
          </div>
        )}

        {generateBun}
      </motion.div>
    </>
  );
};
function randomNoRepeats(array) {
  var ri = Math.floor(Math.random() * array.length); // Random Index position in the array
  var rs = array.splice(ri, 1); // Splice out a random element using the ri var
  /* console.log("RI", ri); */

  /* console.log("array", array); */

  if (array.length < 3) {
    //console.log("push");
    array.push(
      [1, 2, 3, 4, 5, 6],
      [1, 7, 8, 9, 10, 11],
      [1, 12, 13, 14, 15, 16],
      [1, 17, 18, 19, 20, 21],
      [1, 22, 23, 24, 25, 26],
      [1, 27, 28, 29, 30, 31],
      [2, 7, 12, 17, 22, 27],
      [2, 8, 13, 18, 23, 28],
      [2, 9, 14, 19, 24, 29],
      [2, 10, 15, 20, 25, 30],
      [2, 11, 16, 21, 26, 31],
      [3, 7, 13, 19, 25, 31],
      [3, 8, 14, 20, 26, 27],
      [3, 9, 15, 21, 22, 28],
      [3, 10, 16, 17, 23, 29],
      [3, 11, 12, 18, 24, 30],
      [4, 7, 14, 21, 23, 30],
      [4, 8, 15, 17, 24, 31],
      [4, 9, 16, 18, 25, 27],
      [4, 10, 12, 19, 26, 28],
      [4, 11, 13, 20, 22, 29],
      [5, 7, 15, 18, 26, 29],
      [5, 8, 16, 19, 22, 30],
      [5, 9, 12, 20, 23, 31],
      [5, 10, 13, 21, 24, 27],
      [5, 11, 14, 17, 25, 28],
      [6, 7, 16, 20, 24, 28],
      [6, 8, 12, 21, 25, 29],
      [6, 9, 13, 17, 26, 30],
      [6, 10, 14, 18, 22, 31],
      [6, 11, 15, 19, 23, 27]
    );
  }
  //console.log("Array", array);

  let shuffled = rs[0]
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
  //console.log("shufled", shuffled);
  return shuffled;

  //return rs[0];

  /*   var copy = array.slice(0);
  return function () {
    if (copy.length < 1) {
      copy = array.slice(0);
    }
    var index = Math.floor(Math.random() * copy.length);
    var item = copy[index];
    copy.splice(index, 1);

    console.log("copy", copy);

    console.log("item", item);

    let shuffled = item
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    return shuffled;
  }; */
}
function getWidth() {
  return Math.random() * (100 - 50) + 50;
}

export default Bun;
