import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import axios from "axios";

import { staggerItemsFade, itemFade } from "utils/animations";
import Logo from "components/Logo/Logo";
import Wrapper from "components/Wrapper/Wrapper";
import Buns from "./Buns";
import Status from "./Status";

function Game({ pause2, sound2 }) {
  const [clickedSymbol, setClickedSymbol] = useState({
    clicked: 0,
    match: 0,
    nomatch: 0,
  });

  const [showCounter, setShowCounter] = useState(true);
  const [counter, setCounter] = useState(3);
  const [time, setTime] = useState(0);
  const [serverTime, setServerTime] = useState(0);
  const [playersList, setPlayersList] = useState([]);

  let navigate = useNavigate();
  const isOpted = useSelector((state) => state.game.isOptedIn);

  const postStartGame = async () => {
    try {
      const response = await axios.post(
        "https://api.escapebox.si/quizbox/speed/51a06c68-65b2-ldlfav/start",
        {},
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      console.log("data", response.data);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("finally");
    }
  };

  const getLeaderBoard = async () => {
    try {
      const response = await axios.get(
        "https://api.escapebox.si/quizbox/speed/51a06c68-65b2-ldlfav/leader-board",
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      //console.log(response.data);
      setPlayersList(response.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const Countdown = () => {
    return (
      <div>
        <h1>{counter}</h1>
      </div>
    );
  };

  useEffect(() => {
    if (clickedSymbol.clicked === 25) {
      //sound.stop();
      sound2.fade(0.1, 0, 2000);

      setTimeout(() => {
        if (isOpted) {
          navigate("/lestvica");
        } else {
          navigate("/prijava/konec");
        }
      }, 300);
    }
  }, [clickedSymbol]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 800);
    counter === 0 &&
      setTimeout(() => {
        setShowCounter(false);
      }, 800);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    postStartGame();
    getLeaderBoard();
  }, []);

  return (
    <>
      <Wrapper className="game p-0 p-sm-2">
        {showCounter ? (
          <motion.div
            variants={staggerItemsFade}
            initial="hidden"
            animate="show"
            exit="hidden"
            className="countdown d-flex align-items-center justify-content-center rounded">
            <div>
              <div className="w-100 text-center">
                <Countdown />
              </div>
              <div className="record-wrapper text-center w-100">
                <p className="lead fw-bold mb-0">
                  Premagaj rekord:{" "}
                  <span className="fw-normal">
                    <span>0 min &nbsp;</span>
                    <span>26s</span>
                  </span>
                </p>
              </div>
            </div>
          </motion.div>
        ) : (
          <motion.div
            className="buns-container flex-sm-column"
            variants={staggerItemsFade}
            initial="hidden"
            animate="show">
            <motion.div variants={itemFade}>
              <Status
                clickedSymbol={clickedSymbol}
                setClickedSymbol={setClickedSymbol}
                time={time}
                setTime={setTime}
                serverTime={serverTime}
                setServerTime={setServerTime}
              />
            </motion.div>
            <motion.div
              className="d-sm-flex flex-sm-grow-1 align-items-sm-center justify-content-sm-center"
              variants={itemFade}>
              <Buns
                clickedSymbol={clickedSymbol}
                setClickedSymbol={setClickedSymbol}
                time={time}
                setTime={setTime}
                serverTime={serverTime}
                setServerTime={setServerTime}
              />
            </motion.div>

            {/*             <motion.div variants={itemFade} className="text-center d-none">
              <div className="record-wrapper text-center">
                <p className="lead fw-bold mb-0">
                  Premagaj rekord:{" "}
                  <span className="fw-normal">
                    {playersList.map((player) => {
                      if (player.position === 1) {
                        return (
                          <span key={player.position}>
                            {(
                              "0" + Math.floor((player.time / 60000) % 60)
                            ).slice(-2)}{" "}
                            min &nbsp;
                            {(
                              "0" + Math.floor((player.time / 1000) % 60)
                            ).slice(-2)}{" "}
                            sek
                          </span>
                        );
                      }
                    })}
                  </span>
                </p>
              </div>
            </motion.div> */}
          </motion.div>
        )}
      </Wrapper>
    </>
  );
}

export default Game;
