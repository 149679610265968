import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import {
  FacebookShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import useSound from "use-sound";
import { useSelector } from "react-redux";
import { FacebookIcon, ViberIcon, WhatsappIcon } from "react-share";
import ReactCanvasConfetti from "react-canvas-confetti";
import axios from "axios";

import { staggerItemsFade, itemFade } from "utils/animations";
import soundLeaderboard from "assets/sounds/leaderboard.mp3";
import soundLeaderboardTop from "assets/sounds/win.wav";

import imgTop3 from "assets/images/top3.png";
import imgBunOptIn from "assets/images/bun-optin.svg";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import Logo from "components/Logo/Logo";
import Top3 from "./Top3";

import { SoundLeaderboard, SoundLeaderboardTop } from "components/Sounds";

function Leaderboard() {
  const [playersList, setPlayersList] = useState([]);

  let navigate = useNavigate();
  const isSoundEnabled = useSelector((state) => state.game.isSoundEnabled);
  const isOpted = useSelector((state) => state.game.isOptedIn);

  const [playLeaderboard] = useSound(soundLeaderboard, {
    volume: 1,
    soundEnabled: isSoundEnabled,
  });

  const [playLeaderboardTop] = useSound(soundLeaderboardTop, {
    volume: 1,
    soundEnabled: isSoundEnabled,
  });

  // Confetti
  const canvasStyles = {
    position: "absolute",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    bottom: 0,
    left: 0,
    zIndex: 100,
  };

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 1 },
        particleCount: Math.floor(200 * particleRatio),
        colors: ["#C77F7A", "#D1BE4E"],
      });
  }, []);

  const fire = () => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  // Components

  const LeaderBoardPlayers = () => {
    const list = playersList
      .filter((player, index) => player.time > 26000 && player.user !== null)
      .map((player, index) => {
        if (index < 3) {
          return (
            <motion.div
              key={player.user}
              variants={itemFade}
              className="position d-flex align-items-center justify-content-center bg-white rounded-3 text-pink">
              <div className="p-3">
                <h4 className="text-golden">{index + 1}.mesto</h4>
                <p className="fw-bold mb-1">
                  {player.user !== null ? player.user : "Anonimni uporabnik"}
                </p>

                <p className="mb-0">
                  <span>
                    {("0" + Math.floor((player.time / 60000) % 60)).slice(-2)}{" "}
                    min &nbsp;
                  </span>
                  <span>
                    {("0" + Math.floor((player.time / 1000) % 60)).slice(-2)}{" "}
                    sek &nbsp;
                  </span>
                  <span className="d-none">
                    {("0" + ((player.time / 10) % 100)).slice(-2)} ms
                  </span>
                </p>
              </div>
            </motion.div>
          );
        }
      });

    return list;
  };

  const LeaderCurrentPlayer = () => {
    const list = playersList.map((player) => {
      if (player.me) {
        /*         const isTop3 = playersList.some(function (val) {
          return val.me === true && val.position <= 3;
        }); */

        return (
          <div key={player.user}>
            <div className="player-position rounded-3 d-flex justify-content-center align-items-center">
              {player.time <= 26000 ? (
                <div className="p-3 rounded-3 bg-white">
                  <SoundLeaderboardTop />

                  <h2 className="text-black">Ojoj</h2>
                  <p className="text-black">Prišlo je do napake.</p>
                </div>
              ) : player.position <= 3 ? (
                <div className="p-3 rounded-3 bg-white">
                  <SoundLeaderboardTop />

                  <img className="w-100" src={imgTop3} />
                  <h2 className="display-5 fw-bolder mt-3 mb-2 text-golden">
                    {player.position}. mesto
                  </h2>
                  <p className="lead mb-1 text-golden">
                    {player.user !== null ? player.user : "Anonimni uporabnik"}
                  </p>
                  <p className="lead mb-1 fw-normal text-pink">
                    <span>
                      {("0" + Math.floor((player.time / 60000) % 60)).slice(-2)}{" "}
                      min &nbsp;
                    </span>
                    <span>
                      {("0" + Math.floor((player.time / 1000) % 60)).slice(-2)}{" "}
                      sek
                    </span>
                  </p>

                  <div className="share-box mt-3">
                    <div className="d-inline mx-2">
                      <FacebookShareButton url="https://lidl-favorina-2022.escapebox.si">
                        <FacebookIcon
                          iconFillColor="#AC1E21"
                          bgStyle={{ fill: "transparent" }}
                          size={50}
                          round
                        />
                      </FacebookShareButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-3 text-pink">
                  <SoundLeaderboard />
                  <h3 className="text-pink mb-5">
                    Tvoj rezultat
                    <svg
                      width="268"
                      height="60"
                      viewBox="0 0 268 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M25.8286 25.2802L24.5813 28.3812L26.3533 31.2154L23.0186 30.9873L20.8708 33.5484L20.0571 30.3065L16.9577 29.0552L19.7896 27.2795L20.0218 23.9451L22.5857 26.0897L25.8286 25.2802Z"
                        fill="url(#paint0_linear_305_1095)"
                      />
                      <path
                        d="M77.9261 40.143L76.6787 43.244L78.4507 46.0782L75.116 45.8501L72.9682 48.4112L72.1545 45.1693L69.0551 43.9179L71.887 42.1423L72.1193 38.8079L74.6831 40.9525L77.9261 40.143Z"
                        fill="url(#paint1_linear_305_1095)"
                      />
                      <path
                        d="M179.338 31.8827L178.091 34.9838L179.863 37.8179L176.528 37.5899L174.38 40.1509L173.566 36.909L170.467 35.6577L173.299 33.8821L173.531 30.5477L176.095 32.6922L179.338 31.8827Z"
                        fill="url(#paint2_linear_305_1095)"
                      />
                      <path
                        d="M248.664 7.12297L247.417 10.224L249.189 13.0581L245.854 12.8301L243.706 15.3912L242.893 12.1492L239.793 10.8979L242.625 9.1223L242.858 5.78789L245.421 7.93245L248.664 7.12297Z"
                        fill="url(#paint3_linear_305_1095)"
                      />
                      <path
                        d="M125.254 33.7061L126.352 36.8633L129.55 37.8352L126.886 39.8546L126.95 43.1965L124.206 41.2874L121.048 42.3808L122.016 39.1815L120 36.5154L123.341 36.4473L125.254 33.7061Z"
                        fill="url(#paint4_linear_305_1095)"
                      />
                      <path
                        d="M220.991 40.3086L222.088 43.4658L225.286 44.4378L222.623 46.4571L222.687 49.799L219.943 47.8899L216.785 48.9833L217.752 45.784L215.736 43.1179L219.078 43.0498L220.991 40.3086Z"
                        fill="url(#paint5_linear_305_1095)"
                      />
                      <path
                        d="M17.8564 5.27658L18.3703 6.755L19.8679 7.21014L18.6207 8.15576L18.6506 9.72067L17.3658 8.82668L15.8867 9.3387L16.3399 7.84056L15.3959 6.5921L16.9608 6.56019L17.8564 5.27658Z"
                        fill="url(#paint6_linear_305_1095)"
                      />
                      <path
                        d="M146.606 41.5905L147.12 43.069L148.618 43.5241L147.37 44.4697L147.4 46.0346L146.116 45.1406L144.636 45.6527L145.09 44.1545L144.146 42.9061L145.711 42.8742L146.606 41.5905Z"
                        fill="url(#paint7_linear_305_1095)"
                      />
                      <path
                        d="M194.475 34.9875L194.989 36.4659L196.486 36.9211L195.239 37.8667L195.269 39.4316L193.984 38.5376L192.505 39.0496L192.958 37.5515L192.014 36.303L193.579 36.2711L194.475 34.9875Z"
                        fill="url(#paint8_linear_305_1095)"
                      />
                      <path
                        d="M163.113 38.2888L163.626 39.7672L165.124 40.2223L163.877 41.168L163.907 42.7329L162.622 41.8389L161.143 42.3509L161.596 40.8528L160.652 39.6043L162.217 39.5724L163.113 38.2888Z"
                        fill="url(#paint9_linear_305_1095)"
                      />
                      <path
                        d="M202.728 44.8918L203.242 46.3702L204.739 46.8254L203.492 47.771L203.522 49.3359L202.237 48.4419L200.758 48.9539L201.211 47.4558L200.267 46.2073L201.832 46.1754L202.728 44.8918Z"
                        fill="url(#paint10_linear_305_1095)"
                      />
                      <path
                        d="M262.15 21.7829L262.664 23.2613L264.162 23.7165L262.915 24.6621L262.945 26.227L261.66 25.333L260.181 25.845L260.634 24.3469L259.69 23.0985L261.255 23.0665L262.15 21.7829Z"
                        fill="url(#paint11_linear_305_1095)"
                      />
                      <path
                        d="M237.391 23.4338L237.905 24.9122L239.403 25.3674L238.155 26.313L238.185 27.8779L236.9 26.9839L235.421 27.4959L235.875 25.9978L234.931 24.7493L236.495 24.7174L237.391 23.4338Z"
                        fill="url(#paint12_linear_305_1095)"
                      />
                      <path
                        d="M43.1623 47.7776L43.6763 49.256L45.1738 49.7111L43.9266 50.6567L43.9565 52.2216L42.6717 51.3277L41.1926 51.8397L41.6459 50.3415L40.7018 49.0931L42.2667 49.0612L43.1623 47.7776Z"
                        fill="url(#paint13_linear_305_1095)"
                      />
                      <path
                        d="M265.052 54.9729L265.566 56.4513L267.063 56.9064L265.816 57.8521L265.846 59.417L264.561 58.523L263.082 59.035L263.535 57.5369L262.591 56.2884L264.156 56.2565L265.052 54.9729Z"
                        fill="url(#paint14_linear_305_1095)"
                      />
                      <path
                        d="M7.37283 2.24482L8.92241 2.02432L9.65663 0.642021L10.3452 2.04763L11.8867 2.31875L10.7627 3.40796L10.9812 4.95782L9.59793 4.22538L8.19145 4.91213L8.46059 3.37025L7.37283 2.24482Z"
                        fill="url(#paint15_linear_305_1095)"
                      />
                      <path
                        d="M48.6385 38.5583L50.188 38.3378L50.9223 36.9555L51.6108 38.3611L53.1523 38.6322L52.0283 39.7214L52.2468 41.2713L50.8636 40.5389L49.4571 41.2256L49.7262 39.6837L48.6385 38.5583Z"
                        fill="url(#paint16_linear_305_1095)"
                      />
                      <path
                        d="M0.77029 17.1003L2.31987 16.8798L3.05409 15.4975L3.74264 16.9031L5.28417 17.1742L4.16013 18.2634L4.37864 19.8133L2.9954 19.0809L1.58891 19.7676L1.85805 18.2257L0.77029 17.1003Z"
                        fill="url(#paint17_linear_305_1095)"
                      />
                      <path
                        d="M235.16 43.5109L236.71 43.2904L237.444 41.9081L238.133 43.3137L239.674 43.5849L238.55 44.6741L238.769 46.2239L237.386 45.4915L235.979 46.1782L236.248 44.6364L235.16 43.5109Z"
                        fill="url(#paint18_linear_305_1095)"
                      />
                      <path
                        d="M91.5554 35.2575L93.105 35.037L93.8392 33.6547L94.5278 35.0603L96.0693 35.3314L94.9453 36.4207L95.1638 37.9705L93.7806 37.2381L92.3741 37.9248L92.6432 36.3829L91.5554 35.2575Z"
                        fill="url(#paint19_linear_305_1095)"
                      />
                      <path
                        d="M104.76 30.3054L106.31 30.0849L107.044 28.7026L107.733 30.1082L109.274 30.3793L108.15 31.4685L108.369 33.0184L106.985 32.2859L105.579 32.9727L105.848 31.4308L104.76 30.3054Z"
                        fill="url(#paint20_linear_305_1095)"
                      />
                      <path
                        d="M250.596 20.1325L251.11 21.611L252.607 22.0661L251.36 23.0117L251.39 24.5766L250.105 23.6826L248.626 24.1947L249.079 22.6965L248.135 21.4481L249.7 21.4162L250.596 20.1325Z"
                        fill="url(#paint21_linear_305_1095)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_305_1095"
                          x1="21.3557"
                          y1="33.4808"
                          x2="30.8423"
                          y2="27.8908"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_305_1095"
                          x1="73.4531"
                          y1="48.3436"
                          x2="82.9397"
                          y2="42.7536"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_305_1095"
                          x1="174.865"
                          y1="40.0834"
                          x2="184.352"
                          y2="34.4933"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint3_linear_305_1095"
                          x1="244.191"
                          y1="15.3236"
                          x2="253.678"
                          y2="9.73357"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint4_linear_305_1095"
                          x1="127.271"
                          y1="42.8269"
                          x2="130.749"
                          y2="32.3794"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint5_linear_305_1095"
                          x1="223.008"
                          y1="49.4294"
                          x2="226.486"
                          y2="38.982"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint6_linear_305_1095"
                          x1="18.8009"
                          y1="9.5476"
                          x2="20.4295"
                          y2="4.65534"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint7_linear_305_1095"
                          x1="147.551"
                          y1="45.8616"
                          x2="149.179"
                          y2="40.9693"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint8_linear_305_1095"
                          x1="195.419"
                          y1="39.2585"
                          x2="197.048"
                          y2="34.3663"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint9_linear_305_1095"
                          x1="164.057"
                          y1="42.5598"
                          x2="165.686"
                          y2="37.6675"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint10_linear_305_1095"
                          x1="203.672"
                          y1="49.1628"
                          x2="205.301"
                          y2="44.2706"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint11_linear_305_1095"
                          x1="263.095"
                          y1="26.0539"
                          x2="264.723"
                          y2="21.1617"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint12_linear_305_1095"
                          x1="238.336"
                          y1="27.7048"
                          x2="239.964"
                          y2="22.8126"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint13_linear_305_1095"
                          x1="44.1068"
                          y1="52.0486"
                          x2="45.7354"
                          y2="47.1563"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint14_linear_305_1095"
                          x1="265.996"
                          y1="59.2439"
                          x2="267.625"
                          y2="54.3516"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint15_linear_305_1095"
                          x1="11.7457"
                          y1="2.13795"
                          x2="7.25718"
                          y2="-0.399614"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint16_linear_305_1095"
                          x1="53.0114"
                          y1="38.4514"
                          x2="48.5228"
                          y2="35.9139"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint17_linear_305_1095"
                          x1="5.14319"
                          y1="16.9934"
                          x2="0.654643"
                          y2="14.4559"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint18_linear_305_1095"
                          x1="239.533"
                          y1="43.4041"
                          x2="235.045"
                          y2="40.8665"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint19_linear_305_1095"
                          x1="95.9283"
                          y1="35.1506"
                          x2="91.4398"
                          y2="32.6131"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint20_linear_305_1095"
                          x1="109.133"
                          y1="30.1985"
                          x2="104.645"
                          y2="27.6609"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                        <linearGradient
                          id="paint21_linear_305_1095"
                          x1="251.54"
                          y1="24.4036"
                          x2="253.169"
                          y2="19.5113"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#BCAC49" />
                          <stop offset="1" stop-color="#FFF2A5" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </h3>

                  <p className="lead mb-4">
                    {player.user !== null ? player.user : "Anonimni uporabnik"}
                  </p>
                  <p className="lead mb-1 fw-normal">
                    {console.log()}
                    <span>
                      {("0" + Math.floor((player.time / 60000) % 60)).slice(-2)}{" "}
                      min &nbsp;
                    </span>
                    <span>
                      {("0" + Math.floor((player.time / 1000) % 60)).slice(-2)}{" "}
                      sek
                    </span>
                  </p>
                  <p className="lead mb-0">
                    {player.position}. mesto na lestvici
                  </p>
                  <div className="share-box mt-3">
                    <div className="d-inline mx-2">
                      <FacebookShareButton url="https://lidl-favorina-2022.escapebox.si">
                        <FacebookIcon
                          iconFillColor="#AC1E21"
                          bgStyle={{ fill: "transparent" }}
                          size={50}
                          round
                        />
                      </FacebookShareButton>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {!isOpted && (
              <div className="notoptin rounded-3 d-flex justify-content-center align-items-center text-start p-4 mt-1">
                <div className="icon pe-4">
                  <img src={imgBunOptIn} />
                </div>
                <div className="text">
                  <p className="lead fw-normal mb-0">
                    Še nisi prijavljen v nagradno igro?&nbsp;
                    <button
                      className="button-link text-yellow px-0"
                      onClick={() => navigate("/prijava/konec")}>
                      Prijavi se&nbsp;
                    </button>
                    in sodeluj v žrebu za nagrado.
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      }
    });

    return list;
  };

  useEffect(() => {
    setTimeout(() => {
      fire();
    }, 1000);
  }, [fire]);

  useEffect(() => {
    const getLeaderBoard = async () => {
      try {
        const response = await axios.get(
          "https://api.escapebox.si/quizbox/speed/51a06c68-65b2-ldlfav/leader-board",
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        setPlayersList(response.data);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    getLeaderBoard();
  }, []);

  return (
    <>
      <Logo />
      <AnimatePresence>
        <motion.div
          key="leader"
          className="leaderboard  mx-auto text-center py-4 px-2 py-sm-5"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          <motion.div
            variants={staggerItemsFade}
            initial="hidden"
            animate="show">
            <motion.div variants={itemFade}>
              <h1 className="display-5 fw-bold text-golden pb-2">
                Lestvica najboljših
              </h1>

              <p className="lead mt-3">
                Izmed vseh igralcev, ki so se prijavili v žreb, bodo najhitrejši
                trije prejeli{" "}
                <button
                  className="button-link text-yellow p-0"
                  onClick={() => navigate("/nagrade")}>
                  najslajše nagrade
                </button>
                , s sladkim presenečenjem&nbsp; pa bomo razveselili še 7 srečnih
                izžrebancev.
              </p>
              <p className="lead mt-1">
                Med čakanjem na žreb si lahko ogledaš še našo{" "}
                <a href="https://www.lidl.si/c/favorina/c3176" target="_blank">
                  sladko ponudbo
                </a>
                .
              </p>
            </motion.div>

            <div className="row g-0 g-sm-4 mt-4">
              <motion.div variants={itemFade} className="col-sm-6">
                <LeaderCurrentPlayer />
                {/* <ReactCanvasConfetti
                  refConfetti={getInstance}
                  style={canvasStyles}
                /> */}
              </motion.div>
              <div className="col-sm-6">
                <div className="all-positions mt-5 mt-sm-0">
                  <LeaderBoardPlayers />
                </div>
              </div>
            </div>

            <motion.div
              variants={itemFade}
              className="row mt-6 px-sm-5 g-0 g-sm-3 ">
              <div className="col-sm-6 mb-2 mb-sm-0">
                <Button
                  onClick={() => navigate("/pravila")}
                  label="Igraj ponovno"
                  className="btn btn-outline-white w-100"
                />
              </div>
              <div className="col-sm-6">
                <a target="_blank" href="https://www.lidl.si/c/favorina/c3176">
                  <Button
                    label="Sladka ponudba"
                    className="btn btn-gold w-100 px-1"
                  />
                </a>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default Leaderboard;
